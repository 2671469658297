import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";

const useSnackbar = () => {
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
		"success",
	);

	const showSnackbar = (message: string, severity: "success" | "error") => {
		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setOpenSnackbar(true);
	};

	const handleCloseSnackbar = (
		event?: React.SyntheticEvent | Event,
		reason?: string,
	) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};

	const SnackbarComponent = () => (
		<Snackbar
			open={openSnackbar}
			autoHideDuration={10000}
			onClose={handleCloseSnackbar}
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			TransitionComponent={Slide}
		>
			<Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
				{snackbarMessage}
			</Alert>
		</Snackbar>
	);

	return { showSnackbar, SnackbarComponent };
};

export default useSnackbar;
